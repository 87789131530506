@import "../../../styles/vars";
@import "../../../styles/colors";

.container {
  background: red;
  height: 100vh;
}

.sidebar {
  background: $light-grey;
  width: $sidebar-width;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sider-title {
  font-size: $sider-menu-title;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.66px;
  padding: 30px 0 0 20px;
  margin-bottom: 40px;
}

.header {
  background: $white;
  border-bottom: 1px solid $light-grey;
  height: auto;
  line-height: normal;
  padding: 8px 50px;

  :global .ant-input-affix-wrapper {
    border: 2px solid $light-grey;
    height: 40px;
    box-shadow: none;
    font-size: 14px;

    .ant-input {
      font-size: inherit;
    }
  }

  :global .ant-input-group {
    .ant-btn {
      background: $active-color;
      box-shadow: none;
      border: none;
      color: $white;
      border-left: 1px solid $light-grey;
      height: 40px;
    }
  }
}

.menu {
  background: transparent;

  :global .ant-menu-item-group-title {
    padding: 8px 16px;
    font-size: 14px;
    color: #8a8a8a;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
  }

  :global .ant-menu-item {
    height: 30px !important;
    margin: 0;
    font-size: 14px;
    font-weight: $medium;
    display: flex;
    align-items: center;

    .ant-menu-item-icon {
      font-size: 18px;
    }
  }
}

.content-container {
  overflow: auto;
}
