@import "../../../styles/colors";
@import "../../../styles/vars";

.container {
  :global .ant-modal-header {
    background: $light-grey;
  }

  :global .ant-modal-body {
    max-height: 75vh;
    overflow-y: auto;
  }

  :global .ant-avatar {
    background: transparent;
    color: $dark;
    border: 1px solid $grey-3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.folders {
  align-items: center;
}
