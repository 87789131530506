@import "../../../styles/colors";
@import "../../../styles/vars";

.header {
  background: #ffffff;
  border-bottom: 1px solid #f3ecea;
}

.logo {
  cursor: pointer;

  img {
    height: 100%;
    padding: 15px;
  }
}

.landing-content {
  height: 100vh;
  background: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;

  :global .login-wrapper {
    background: $white;
    padding: 50px;

    .ant-space-item {
      width: 100%;
    }

    img {
      width: 400px;
      @media screen and (max-width: 768px) {
        width: 190px;
      }
    }
  }

  :global .ant-divider {
    border-top: 1px solid $light-grey;
  }

  :global .ant-btn {
    width: 100%;
  }
}
