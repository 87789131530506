@import "../../../styles/vars";
@import "../../../styles/colors";

.container {
  background: $light-grey;
  height: 45px;
  padding: 5px;
}

.setting-button {
  border: none;
  box-shadow: none;
  font-size: 18px;
}

.avatar {
  bottom: 2px;
  border: 1px solid $active-color;
}
