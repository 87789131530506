// TODO migrate to custom-antd-theme.less

@import "./colors";
@import "./vars";

.ant-modal {
  &.fullscreen {
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;

    .ant-modal-content {
      width: 100vw;
      height: 100vh;
      overflow: auto;
      border-radius: 0;

      .ant-modal-body {
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: calc(100vh - 72px);

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.viewer {
      background: $dark-gray;

      .ant-modal-content {
        background: $dark-gray;
      }
    }
  }

  &.transparent {
    background: transparent !important;

    .ant-modal-content {
      background: $dark-gray-modal !important;

      .ant-modal-close {
        color: $white;
      }

      .ant-modal-header {
        background: transparent;
        color: $white;
        border-bottom: none;

        .ant-modal-title {
          color: inherit;

          .ant-btn {
            background: transparent;
            color: inherit;
            border: none;
          }
        }
      }
    }
  }
}

.ant-tree {
  .ant-tree-node-content-wrapper {
    white-space: pre;
  }
}

.anticon {
  vertical-align: middle;
}

.ant-popover-inner {
  .ant-popover-message {
    font-size: 15px;
    font-weight: $semi;
    background: $light-grey;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 4px 12px;
  }
}


.file-upload-notification {
  padding: 0;

  .ant-notification-notice-content {
    .ant-notification-notice-message {
      padding: 16px;
      border-bottom: 1px solid #f7f7f7;
    }

    .ant-notification-notice-description {
      padding: 20px;
      max-height: 50vh;
      overflow-y: auto;
    }

  }
}
