@import "../../../styles/colors";
@import "../../../styles/vars";

.container {
  padding: 2px 10px;
  border-radius: $default-border-radius;

  &:hover {
    background: $highlighted-color-nav;
  }
}

.root {
  //background: red;
}

.highlighted {
  background: $highlighted-color;
}
