//@import "~antd/dist/antd.variable.min.css";
@import "./custom-antd-theme.css";
@import "./antd-override";

body {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  font-family: "Inter Semibold", serif;
  font-style: normal;
  overflow: hidden;
}

.react-pdf__Page__canvas {
  margin: auto;
}
