@import "../../../styles/colors";
@import "../../../styles/vars";

.container {
  :global .ant-modal-content {
    border-radius: 20px;

    .ant-menu {
      border-radius: 20px;

      .ant-menu-item {
        border-radius: 20px;

        &:hover {
          background: $light-grey;
          color: $active-color;
        }

        a:hover {
          color: $active-color;
        }

        svg {
          font-size: 20px;
        }
      }
    }
  }
}
