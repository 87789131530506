@import "../../../styles/vars";
@import "../../../styles/colors";

.toolbar {
  height: $folder-view-toolbar-height;
  background: $white;
}

.content {
  background: $white;
  border-top: 1px solid $light-grey;
}

.folder-view-content {
  height: $folder-view-content-height;
  overflow: auto;
}

.container {
  height: 100%;
  padding: 0 $default-padding;

  :global .ant-breadcrumb {
    .ant-breadcrumb-link {
      .anticon-home,
      .anticon {
        font-size: 18px !important;
      }

      .ant-typography {
        font-size: 16px;
        font-weight: $medium;
      }
    }
  }
}

.file-viewer-controls {
  .nav-button {
    position: fixed;
    top: 50vh;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    &.next {
      right: 20px;
    }

    &.prev {
      left: 20px;
    }
  }
}
