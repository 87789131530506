@import "../../../styles/colors";
@import "../../../styles/vars";

.container {
  :global .ant-modal-content {
    .ant-modal-header {
      background: $light-grey;

      .ant-modal-title {
        font-size: 15px;
        font-weight: $semi;
        font-style: normal;
        color: $black-primary;
      }
    }

    .ant-modal-body {
      padding: 25px;

      .ant-divider-with-text:before,
      .ant-divider-with-text:after {
        border-top: 1px solid $grey-3;
      }

      .ant-divider-inner-text {
        font-size: 11px;
        font-weight: $bold;
        letter-spacing: 1px;
        color: $grey;
      }
    }
  }
}

.member-invitation {
  :global .ant-space-item {
    &:first-child {
      width: 80%;
    }
  }
}
