$active-color: #C25836;
$highlighted-color: #e3a48b;
$highlighted-color-nav: #FFFFFF;

$grey: #8A8A8A;
$grey-2: #393939;
$grey-3: #8C807C;
$light-grey: #F3ECEA;
$dark-gray: #333;
$dark-gray-modal: #000000b3;
$card-border-grey: #BFBFBF;

$white: #ffffff;

$dark: #000000;
$black-primary: #393939;
$black-secondary: #494949;
