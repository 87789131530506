@import "../../../styles/colors";
@import "../../../styles/vars";

.item-name-container {
  padding: 30px 20px;
  border: 1px solid $light-grey;

  .close-icon {
    border: none;
    box-shadow: none;
  }

  :global .ant-typography {
    font-size: 16px;
    font-weight: $medium;
    font-style: normal;
    color: $black-primary !important;
  }
}

.item-detail-container {
  padding: 30px 20px;
  border: 1px solid $light-grey;
  height: 100%;

  h4 {
    font-size: 14px;
    font-weight: $medium;
    font-style: normal;
    color: $black-primary;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 13px;
    font-weight: $normal;
    font-style: normal;
    color: $black-secondary;
  }

  :global .ant-tabs-nav {
    margin-bottom: 26px;

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: $black-primary;

        &:hover {
          color: $active-color;
        }
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $active-color;
      }
    }

    .ant-tabs-ink-bar {
      background: $active-color;
    }
  }

  :global .ant-avatar {
    border: 2px solid red;
    border-radius: 100%;
  }

  :global .ant-input {
    margin-bottom: 20px;
  }

  //:global .ant-btn {
  //  width: 40%;
  //  margin-left: 20px;
  //}
}

.info-container {
  height: inherit;
  background: $white;
}
