@import "styles/colors";
@import "styles/vars";

.container {
  overflow-x: auto;
  min-height: 100px;
  max-height: calc(100vh - 400px);
  background-color: transparent;
}

.content {
  width: fit-content;
}

.title {
  padding: 8px 16px;
  font-size: $sider-menu-item;
  color: $grey;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
}

.tree {
  background: transparent;
  margin-bottom: 10px;

  :global .ant-tree-treenode {
    padding: 3px;

    .ant-tree-switcher {
      background: transparent;
    }

    &.ant-tree-treenode-selected {
      color: $active-color;
    }

    .ant-tree-node-content-wrapper {
      background: transparent;
    }

    &:first-child {
      background: $white;
      width: 90%;
      border-radius: 0 10px 10px 0;
    }
  }
}
